<script setup lang="ts">
import { Drawer } from 'flowbite';
import { onMounted, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { PROPERTY_STACK, TEST_ESTATES } from '@constants';
import { useAnalyticsStore } from '@analytics/stores/analytics';
import CloseButton from '~/components/navigation/appSidebar/CloseButton.vue';
import LivePropertyIndicator from '~/components/navigation/appSidebar/LivePropertiesIndicator.vue';
import NavigationLink from '~/components/navigation/appSidebar/NavigationLink.vue';
import { useMainStore } from '~/stores/mainStore';
import type { User } from '~/types/user';

const mainStore = useMainStore();
const analyticsStore = useAnalyticsStore();

const { drawer: show, toggleDrawer } = useLayout();

const { user }: { user: User } = useAuth();

const { liveUnreadEnquiriesCount } = storeToRefs(useMainStore());

const betaSidebarBannerDismissed = useCookie('beta_sidebar_banner_dismissed', {
  default: () => false,
  maxAge: 60 * 60 * 24 * 365,
  watch: true,
});

const runtimeConfig = useRuntimeConfig();

const showCommissions = computed(() => {
  if (!user) {
    return false;
  }

  return (
    user.can?.includes('view:commissions') && user.landlord?.commissions_access
  );
});

const showTenanciesV2 = computed(() => {
  return mainStore.siteSettings.canViewV2Tenancies;
});

const showUtilityConnect = computed(() => {
  if (!user) {
    return false;
  }

  return mainStore.siteSettings.canViewUtilityConnect;
});

const showTenancies = computed(() => {
  if (!user) {
    return false;
  }

  return (
    user.landlord.tenancies_enabled &&
    ([PROPERTY_STACK, TEST_ESTATES].includes(user.landlord.id) ||
      ['local', 'uat', 'staging'].includes(runtimeConfig.public.appEnv))
  );
});

const drawerEl = ref<HTMLElement | null>(null);
const drawer = ref<Drawer | null>(null);

onMounted(() => {
  if (drawerEl.value) {
    drawer.value = new Drawer(drawerEl.value, {
      onHide: () => {
        show.value = false;
      },
      onShow: () => {
        show.value = true;
      },
    });
  }
});

const dismissBetaSidebarBanner = () => {
  // Dismiss the banner and save that for a year
  betaSidebarBannerDismissed.value = true;
};

watch(show, () => {
  if (show.value) {
    drawer.value?.show();
  } else {
    drawer.value?.hide();
  }
});

const tenanciesUrl = computed(() => useZiggy('landlord.tenancy.index'));

const agentPortalVersionSwitchUrl = computed(() =>
  useZiggy('landlord.version', '1.0'),
);

const showAnalyticsDropdownMenu = computed(() => {
  return mainStore.siteSettings.showAgentPortalAnalytics;
});
const analyticsDropdownMenuIsOnShow = ref<boolean>(true);

const toggleAnalyticsDropdownMenuIsOnShow = () => {
  analyticsDropdownMenuIsOnShow.value = !analyticsDropdownMenuIsOnShow.value;
};

const contractsDropdownMenuIsOnShow = ref<boolean>(true);

const toggleContractsDropdownMenu = () => {
  contractsDropdownMenuIsOnShow.value = !contractsDropdownMenuIsOnShow.value;
};
</script>

<template>
  <aside
    id="sidebar-menu"
    ref="drawerEl"
    dusk="side-bar"
    class="fixed left-0 top-0 z-40 h-full min-w-72 max-w-2xl -translate-x-full flex-col transition-transform xxl:min-w-[287px] xl:relative xl:block xl:translate-x-0"
    aria-label="Sidebar"
    data-cy="navigation-left"
  >
    <div
      class="relative h-full overflow-y-auto border-r bg-white pb-4 pt-8 dark:border-gray-700 dark:bg-gray-800 xl:pt-4"
    >
      <section class="px-3 py-4">
        <CloseButton @click="toggleDrawer" />
        <LivePropertyIndicator class="mb-0" />
      </section>

      <section class="px-3 pt-6">
        <ul class="space-y-2">
          <li>
            <NavigationLink
              title="Dashboard"
              to="/"
              :icon="['far', 'gauge-max']"
            />
          </li>
          <li>
            <NavigationLink
              title="Properties"
              to="/properties"
              :icon="['far', 'house']"
            />
          </li>
          <li>
            <NavigationLink
              title="Enquiries"
              to="/enquiries"
              :icon="['far', 'envelope']"
              :badge-count="liveUnreadEnquiriesCount"
            />
          </li>
          <li>
            <NavigationLink
              title="Contracts"
              :to="undefined"
              :icon="['far', 'file-signature']"
              :toggle="contractsDropdownMenuIsOnShow"
              class="cursor-pointer"
              data-testid="contracts-menu"
              @toggle="toggleContractsDropdownMenu"
            />
            <Transition name="slide-fade">
              <ul v-show="contractsDropdownMenuIsOnShow">
                <li v-if="showTenancies">
                  <NavigationLink
                    title="Tenancies"
                    :href="tenanciesUrl"
                    :icon="false"
                  />
                </li>
                <li>
                  <NavigationLink
                    v-if="showTenanciesV2"
                    :to="{
                      name: 'tenancies',
                    }"
                    title="Tenancies"
                    :icon="false"
                    :label-required="true"
                    label-text="BETA"
                    label-color="bg-blue-700 text-white"
                    data-testid="tenancies-v2-menu-item"
                  />
                </li>
                <li>
                  <NavigationLink
                    title="Utilities"
                    to="/utilities"
                    :icon="false"
                    data-testid="utilities-menu-item"
                  />
                </li>
                <li v-if="showUtilityConnect">
                  <NavigationLink
                    :to="{
                      name: 'utility-connect',
                    }"
                    title="Utility Connect"
                    :icon="false"
                    :label-required="true"
                    label-text="NEW"
                    label-color="bg-green-500 text-white"
                    data-testid="utility-connect-menu-item"
                  />
                </li>
              </ul>
            </Transition>
          </li>
          <li>
            <NavigationLink
              title="Analytics"
              :to="showAnalyticsDropdownMenu ? undefined : '/analytics'"
              :icon="['far', 'chart-user']"
              :toggle="
                showAnalyticsDropdownMenu
                  ? analyticsDropdownMenuIsOnShow
                  : undefined
              "
              class="cursor-pointer"
              @toggle="toggleAnalyticsDropdownMenuIsOnShow"
            />
            <Transition name="slide-fade">
              <ul
                v-show="
                  showAnalyticsDropdownMenu && analyticsDropdownMenuIsOnShow
                "
              >
                <li>
                  <NavigationLink
                    title="Enquiries"
                    :icon="false"
                    :to="{
                      path: '/analytics/enquiries',
                      query: analyticsStore.query ?? {},
                    }"
                  />
                </li>
                <li>
                  <NavigationLink
                    title="Discovery"
                    :icon="false"
                    :to="{
                      path: '/analytics/discovery',
                      query: analyticsStore.query ?? {},
                    }"
                  />
                </li>
                <li>
                  <NavigationLink
                    title="Portfolio"
                    :icon="false"
                    :to="{
                      path: '/analytics/portfolio',
                      query: analyticsStore.query ?? {},
                    }"
                  />
                </li>
                <li>
                  <NavigationLink
                    title="Market"
                    :icon="false"
                    :to="{
                      path: '/analytics/market',
                      query: analyticsStore.query ?? {},
                    }"
                  />
                </li>
              </ul>
            </Transition>
          </li>
          <li v-if="showCommissions">
            <NavigationLink
              title="Commissions"
              to="/commissions"
              :icon="['far', 'circle-sterling']"
              data-testid="sidebar-commissions"
              :label-required="true"
              label-text="BETA"
              label-color="bg-blue-700 text-white"
            />
          </li>
          <li>
            <NavigationLink
              title="Resources"
              href="https://unihomes.helpdocs.io/"
              :icon="['far', 'book']"
              target="_blank"
              rel="noopener noreferrer"
            />
          </li>
        </ul>
      </section>

      <section
        v-if="
          mainStore.siteSettings.showAgentPortalBetaToggleSwitch &&
          !betaSidebarBannerDismissed
        "
        class="px-3 py-6 xl:absolute xl:bottom-12"
      >
        <div class="rounded-lg border-2 border-blue-500 bg-blue-50 p-4 text-sm">
          <div class="mb-3 flex items-center">
            <span
              class="ml-0 mr-auto inline-block rounded-full bg-blue-700 px-2.5 py-0.5 text-xs font-medium text-white"
              >Beta</span
            >
            <FontAwesomeIcon
              class="cursor-pointer text-base"
              :icon="['fas', 'times']"
              @click="dismissBetaSidebarBanner"
            />
          </div>
          <p class="font-semibold leading-snug">New look, same great tools!</p>
          <p class="mb-4 text-sm leading-snug">
            You’re currently using the new beta version of the Agent Portal.
          </p>
          <p class="mb-4 text-sm leading-snug">
            We’ve worked hard to ensure everything works as you need it to, but
            if you do encounter issues, feel free to switch back to the legacy
            portal.
          </p>
          <p>
            <a
              :href="agentPortalVersionSwitchUrl"
              class="font-semibold underline"
            >
              Switch back
            </a>
          </p>
        </div>
      </section>

      <!-- App sidebar footer -->
      <section
        v-show="!analyticsDropdownMenuIsOnShow"
        class="bottom-0 w-full p-3 lg:absolute"
      >
        <p class="text-center text-xs leading-snug opacity-50">
          Build Date: {{ runtimeConfig.public.buildDate }}
        </p>
      </section>
    </div>
  </aside>
</template>

<style scoped lang="scss">
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
