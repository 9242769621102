<script setup lang="ts">
import type {
  LocationQuery,
  LocationQueryRaw,
  RouteLocation,
} from 'vue-router';
import UhTooltip from '~/components/general/uhTooltip/UhTooltip.vue';

type NavigationLinkProps = {
  title: string;
  icon: string[] | boolean;
  closeSidebar?: boolean;
  pill?: string;
  to?: string | RouteLocation | object | undefined;
  query?: LocationQuery | LocationQueryRaw | undefined;
  href?: string | undefined;
  badgeCount?: number | undefined;
  disabled?: boolean;
  toggle?: boolean | undefined;
  tooltip?: string | undefined;
  labelRequired?: boolean;
  labelText?: string | undefined;
  labelColor?: string | undefined;
};

type NavigationLinkEmits = {
  (e: 'closeSidebar'): void;
  (e: 'toggle'): void;
};

const props = withDefaults(defineProps<NavigationLinkProps>(), {
  to: undefined,
  query: undefined,
  href: undefined,
  closeSidebar: true,
  pill: '',
  badgeCount: undefined,
  disabled: false,
  toggle: undefined,
  tooltip: undefined,
  labelRequired: false,
  labelText: undefined,
  labelColor: 'bg-blue-750 text-white',
  icon: false,
});

const emit = defineEmits<NavigationLinkEmits>();

const countLabel = computed(() => {
  if (!props.badgeCount || props.badgeCount === 0) return;

  return props.badgeCount > 99 ? '99+' : props.badgeCount.toString();
});

/**
 * For every click, trigger the closing of the sidebar
 * If the menu item is a toggle, then emit the toggle
 * The chevron is now just a visual indicator and not its own button
 */
const clicked = () => {
  emit('closeSidebar');

  if (props.toggle !== undefined) {
    emit('toggle');
  }
};
</script>
<template>
  <NuxtLink
    :to="to"
    :href="href"
    class="group flex items-center rounded-lg px-4 py-2 text-base font-normal hover:bg-gray-50"
    :class="disabled ? 'text-gray-400' : 'text-gray-900'"
    active-class="nav-left-link--active"
    @click="clicked"
  >
    <!-- + Icon -->
    <div class="relative">
      <FontAwesomeIcon
        v-if="icon"
        :icon="icon"
        size="lg"
        class="icon h-[24px] w-[24px] text-gray-400 transition-colors group-hover:text-gray-400 group-[.router-link-exact-active]:text-gray-400 dark:group-hover:text-white dark:group-[.router-link-exact-active]:text-white"
      />
    </div>
    <!-- - Icon -->
    <!-- + Text -->
    <span
      class="ml-3"
      :class="{
        'ml-3 flex-1 whitespace-nowrap': pill,
        '!ml-[2.25rem]': !icon,
      }"
    >
      <UhTooltip v-if="tooltip" hover placement="top">
        {{ title }}
        <template #content>
          <div class="w-max max-w-[8rem]">
            {{ tooltip }}
          </div>
        </template>
      </UhTooltip>
      <span v-else>{{ title }}</span>
    </span>
    <!-- - Text -->
    <!-- + Badge -->
    <span
      v-if="badgeCount"
      class="ml-auto inline-flex min-h-5 min-w-5 items-center justify-center rounded-full bg-red-100 px-2 py-0.5 text-xs font-medium text-red-700 dark:bg-blue-200 dark:text-blue-800"
    >
      {{ countLabel }}
    </span>
    <!-- - Badge -->
    <!-- + Custom Label -->
    <span
      v-if="labelRequired"
      :class="`ml-auto mr-0 inline-block rounded-full px-2.5 py-0.5 text-sm text-xs font-semibold ${labelColor}`"
    >
      {{ labelText }}
    </span>
    <!-- - Custom Label -->
    <!-- + Toggle -->
    <FontAwesomeIcon
      v-if="toggle !== undefined"
      :icon="['far', 'chevron-down']"
      size="lg"
      class="icon ml-auto h-[24px] w-[24px] transform text-gray-400 transition-all ease-in-out group-hover:text-gray-400 group-[.router-link-exact-active]:text-gray-400"
      :class="toggle ? 'rotate-180' : 'rotate-0'"
    />
    <!-- - Toggle -->
  </NuxtLink>
</template>

<style scoped lang="scss">
.nav-left-link--active {
  @apply font-semibold;

  @media (prefers-color-scheme: dark) {
    @apply bg-blue-500/20;
  }

  .icon {
    @apply text-gray-900;
  }
}
</style>
